<template>
  <Layout :tituloPagina="'Notificaciones | Visor de mensaje'">
    <div class="row">
      <div class="col-lg-9 col-12">
        <div class="mb-3">
          <div class="card">
            <div class="card-header bg-transparent border-bottom text-uppercase">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                  <template v-if="mensaje.id_usuario_emisor != null">
                    <img
                      :src="
                        `${API}/usuarios/${
                          mensaje.id_usuario_emisor
                        }/foto?_tk=${token}&tipo_foto=64x64`
                      "
                      class="rounded-circle avatar-sm"
                      v-if="mensaje.existe_foto_usuario_emisor"
                    />
                    <img alt="image" class="rounded-circle avatar-sm" :src="usuarioicon" v-if="!mensaje.existe_foto_usuario_emisor">
                  </template>
                  <template v-if="mensaje.id_usuario_emisor == null">
                    <div class="auth-logo">
                      <img 
                        alt="image" :src="argusicon"
                        class="avatar-sm rounded-circle avatar-sm auth-logo-dark"
                      />
                      <img 
                        alt="image" :src="argusiconblanco"
                        class="avatar-sm rounded-circle avatar-sm auth-logo-light"
                      />
                    </div>
                  </template>
                </div>
                <div class="flex-grow-1">
                  <h5 class="font-size-14 mb-0">{{mensaje.id_usuario_emisor != null ? mensaje.nombre_usuario_emisor : 'Sistema ArgusBlack'}}</h5>
                  <small class="text-muted">{{formatoFecha(mensaje.created_at, 'dddd, DD/MM/YYYY, h:mm a')}}</small>
                </div>
                <h5 class="font-size-16 mb-0 bx-pull-right">
                  {{mensaje.asunto}}
                </h5>
              </div>
            </div>
            <div class="card-body">
              <div v-html="mensaje.mensaje"></div>
            </div>
          </div>
          <div class="text-right">
            <div class="btn-group">
              <button
                class="btn btn-secondary"
                @click="cerrar()"
              >
                <i class="mdi mdi-chevron-left"></i> Atras
              </button>
              <button
                title="Eliminar mensaje"
                class="btn btn-danger"
                @click="eliminarMensaje(mensaje)"
              >
                <i class="mdi mdi-trash-can-outline"></i> Eliminar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import MensajeUsuarioSrv from '@/services/MensajeUsuarioSrv.js'
import usuarioicon from '@/assets/img/usuario.png'
import argusicon from '@/assets/img/logoArgus.png'
import argusiconblanco from '@/assets/img/logoArgusBlanco.png'
import moment from 'moment'
import API from '@/API.js'

export default {
  name: 'VisorMensaje',
  components: {
    Layout
  },
  data() {
    return {
      API: API,
      token: localStorage.getItem('argusblack.token'),
      usuarioicon: usuarioicon,
      argusicon: argusicon,
      argusiconblanco: argusiconblanco,
      modo: 'visor',
      mensaje: {
        asunto: "",
        created_at: "",
        existe_foto_usuario_emisor: false,
        existe_foto_usuario_receptor: false,
        id: null,
        id_usuario_emisor: null,
        id_usuario_receptor: null,
        mensaje: "",
        nombre_usuario_emisor: "",
        nombre_usuario_receptor: "",
        visto: 0
      },
      bandera_spinner: false
    }
  },
  created: function() {
    var self = this
    
    self.cargarMensaje()

    // Se manda a actualizar el visor
    iu.bus.on('mensajeActualizar', function(idMensaje) {
      self.cargarMensaje(idMensaje)
    })
  },
  mounted (){
    document.querySelectorAll( 'oembed[url]' ).forEach( element => {
      iframely.load( element, element.attributes.url.value );
    })
  },
  methods: {
    atras: function() {
      this.$router.go(-1)
    },

    cargarMensaje(idMensaje){
      var self = this

      let id_mensaje = idMensaje ? idMensaje : parseInt(self.$route.params.id)

      MensajeUsuarioSrv.mensajeJSON(id_mensaje).then(response => {
        self.mensaje = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el mensaje '+id_mensaje
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.atras()
    },

    eliminarMensaje(mensaje){
      var self = this 

      var nombre = mensaje.id_usuario_emisor != null ? mensaje.nombre_usuario_emisor : 'Sistema ArgusBlack'
      
      MensajeUsuarioSrv.eliminar(mensaje.id).then(response => {
        iu.msg.success("Mensaje de "+nombre+" eliminado.");
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se pudo eliminar el mensaje de "+nombre
        }
        iu.msg.error(mensaje);
        console.log(error)
      }).finally(firn => {
        self.cerrar()
        iu.bus.emit('actualizarCampana')
      })
    },

    formatoFecha(mt, formato = 'DD/MM/YYYY') {
      return moment(mt).locale('es-mx').format(formato)
    }
  },
}
</script>

<style scoped>

</style>